
import { required } from 'vuelidate/lib/validators'
import { omit, map } from 'lodash'
import toggleModalWindow from '@/mixins/toggleModalWindow'
import { apiPostQuestionData } from '@/services/questions'

/* eslint-disable */
export default {
    mixins: [toggleModalWindow],
    validations () {
        return {
            questionsData: {
                name: { required },
                phone: { required },
                language: { required },
            },
        }
    },
    data () {
        return {
            translations: {
                phoneNumberLabel: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.ELEMENT_5.PLACEHOLDER')
            },
            questionsData: {
                name: null,
                phone: null,
                language: null,
                questions: [
                    {
                        id: 0,
                        step: 1,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_1.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_1.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_1.ANSWERS.ANSWER_2',
                                ),
                            },
                            {
                                id: 2,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_1.ANSWERS.ANSWER_3',
                                ),
                            },
                            {
                                id: 3,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_1.ANSWERS.ANSWER_4',
                                ),
                            },
                        ],
                    },
                    {
                        id: 1,
                        step: 2,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_2.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_2.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_2.ANSWERS.ANSWER_2',
                                ),
                            },
                            {
                                id: 2,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_2.ANSWERS.ANSWER_3',
                                ),
                            },
                        ],
                    },
                    {
                        id: 2,
                        step: 3,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_3.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_3.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_3.ANSWERS.ANSWER_2',
                                ),
                            },
                            {
                                id: 2,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_3.ANSWERS.ANSWER_3',
                                ),
                            },
                            {
                                id: 3,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_3.ANSWERS.ANSWER_4',
                                ),
                            },
                        ],
                    },
                    {
                        id: 3,
                        step: 4,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_4.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'number',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_4.ANSWERS.ANSWER_1',
                                ),
                            },
                        ],
                    },
                    {
                        id: 4,
                        step: 5,
                        type: 'Radio',
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.ANSWERS.ANSWER_2',
                                ),
                            },
                            {
                                id: 2,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.ANSWERS.ANSWER_3',
                                ),
                            },
                            {
                                id: 3,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.ANSWERS.ANSWER_4',
                                ),
                            },
                            {
                                id: 4,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_5.ANSWERS.ANSWER_5',
                                ),
                            },
                        ],
                    },
                    {
                        id: 5,
                        step: 6,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_6.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_6.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_6.ANSWERS.ANSWER_2',
                                ),
                            },
                            {
                                id: 2,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_6.ANSWERS.ANSWER_3',
                                ),
                            },
                        ],
                    },
                    {
                        id: 6,
                        step: 7,
                        question: this.$t('MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_7.QUESTION'),
                        answer: '',
                        answerOption: [
                            {
                                id: 0,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_7.ANSWERS.ANSWER_1',
                                ),
                            },
                            {
                                id: 1,
                                type: 'radio',
                                title: this.$t(
                                    'MODAL_WINDOWS.QUESTION_MODAL.FORMS_ELEMENTS.QUESTIONS.QUESTION_7.ANSWERS.ANSWER_2',
                                ),
                            },
                        ],
                    },
                ],
            },
            showLeadForm: false,
            currentStep: 1,
        }
    },
    computed: {
        currentQuestion () {
            return this.questionsData.questions.find(question => question.step === this.currentStep)
        },
    },
    methods: {
        nextStep () {
            if (this.currentStep < this.questionsData.questions.length) {
                this.currentStep++
            } else {
                this.showLeadForm = true
            }
        },
        async submitForm () {
            this.$v.$touch()
            if (!this.$v.questionsData.$error) {
                const requestQuestions = map(this.questionsData.questions, (question) =>  omit(question, ['id', 'step', 'answerOption']))
                const requestData = { ...omit(this.questionsData, ['questions']), questions: requestQuestions }
                await apiPostQuestionData(requestData)
                this.$gtm.push({ event: 'cvSubmitted' })
                this.closeQuestionModal()
            }
        },
        closeQuestionModal () {
            this.closeModal('questions-modal')
        },
    },
}
