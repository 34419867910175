import sanitizeHtml from 'sanitize-html'

export default {
    methods: {
        sanitizeSvgHtml (svg) {
            return sanitizeHtml(svg, {
                allowedTags: [
                    'svg', 'path', 'mask', 'g', 'defs', 'rect', 'pattern', 'use', 'radialgradient', 'stop', 'image',
                ],
                allowedAttributes: {
                    svg: ['style', 'class', 'shape-rendering', 'height', 'width',
                        'viewBox', 'style', 'fill', 'xmlns', 'xmlns:xlink'],
                    path: ['fill', 'd', 'stroke', 'stroke-width'],
                    mask: ['id', 'style', 'maskUnits', 'x', 'y', 'width', 'height'],
                    rect: ['id', 'style', 'fill', 'fill-opacity', 'width', 'height'],
                    g: ['mask'],
                    pattern: ['id', 'patterncontentunits', 'width', 'height'],
                    use: ['xlink:href', 'transform', 'maskUnits', 'x', 'y', 'width', 'height'],
                    radialgradient: ['id', 'cx', 'cy', 'r', 'gradientUnits', 'gradientTransform'],
                    stop: ['offset', 'stop-color'],
                    image: ['id', 'style', 'xlink:href', 'width', 'height'],
                },
            })
        },
        sanitizeTextHtml (svg) {
            return sanitizeHtml(svg, {
                allowedTags: ['span'],
                allowedAttributes: {
                    span: ['class'],
                },
            })
        },
    },
}
