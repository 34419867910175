import { render, staticRenderFns } from "./hebrewDefault.vue?vue&type=template&id=37292f22"
import script from "./hebrewDefault.vue?vue&type=script&lang=js"
export * from "./hebrewDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsHebrewHeaderComponent: require('/usr/src/app/components/layouts/HebrewHeaderComponent.vue').default,LayoutsHebrewFooterComponent: require('/usr/src/app/components/layouts/HebrewFooterComponent.vue').default})
