
import LayoutsFooterPartnersComponent from '@/components/layouts/FooterPartnersComponent'
export default {
    components: {
        LayoutsFooterPartnersComponent
    },
    mounted() {
        const WOW = require('wow.js')
        new WOW().init()
    },
}
