import Vue from 'vue'
import { addLead } from 'thrivestate-lead-frontend-library'

export async function apiCreateLead (lead, baseUrl = process.env.API_URL) {
    try {
        const message = await addLead(lead, baseUrl)
        Vue.notify({
            title: 'Success',
            text: message.data.message,
            type: 'success',
            duration: 3000,
        })
        return message
    } catch (error) {
        Vue.notify({
            title: 'Error',
            text: error,
            type: 'error',
            duration: 3000,
        })
        console.error(error?.data?.message || error)
        return null
    }
}
