
export default {
    data () {
        this.pk = process.env.STRIPE_PUBLISHABLE_KEY
        return {
            StripeElementPayment: null,
            elementsOptions: {
                appearance: {},
                clientSecret: 'pi_3KIBJdMLhhr1DIBc5qg_secret_skk3HIXCXtGjDA',
            },
            confirmParams: {
                return_url: 'http://localhost:8080/success',
            },
        }
    },
    async mounted () {
        const stripeCheckout = async () => await import('@vue-stripe/vue-stripe')
        // const { clientSecret } = await fetch('/secret', { method: 'POST' }).then(r => r.json)
        this.StripeElementPayment = (await stripeCheckout()).StripeElementPayment
    },
    methods: {
        setStep (step) {
            this.$emit('setStep', step)
        },
    },
}
