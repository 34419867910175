
import { required, email } from 'vuelidate/lib/validators'
import { apiGetAgent } from '@/services/agents'
import { apiCreateLead } from '@/services/lead'
import handleToken from '@/mixins/handleToken'

export default {
    mixins: [handleToken],
    validations () {
        return {
            lead: {
                name: { required },
                phone: { required },
                email: { required, email },
            },
        }
    },
    data () {
        return {
            loading: false,
            agent: null,
            step: 1,
            dateData: {
                date: null,
                time: null,
            },
            lead: {
                name: null,
                phone: null,
                email: null,
            },
        }
    },
    watch: {
        async '$route.query.utm_source' () {
            await this.createLead(6)
        },
    },
    async mounted () {
        this.$store.commit('startLoading')
        if (this.$store.state.currentAgent) {
            this.agent = this.$store.state.currentAgent
            this.$store.commit('setCurrentAgent', null)
        } else {
            this.agent = await apiGetAgent()
        }
        this.$store.commit('endLoading')
    },
    methods: {
        async createLead (step) {
            this.$v.$touch()
            if (!this.dateData.time) {
                this.$notify({
                    title: 'Warning',
                    text: 'Сhoose a time, please',
                    type: 'Warning',
                    duration: 3000,
                })
            } else if (!this.$v.lead.$error) {
                if (!this.$route.query.utm_source) {
                    if (this.getToken()) {
                        await this.$router.push({ query: {
                            ...this.$route.query, ...this.getToken() },
                        })
                    } else {
                        await this.$router.push({ query: {
                            ...this.$route.query, utm_source: 'organic' },
                        })
                    }
                } else {
                    this.loading = true
                    const response = await apiCreateLead({
                    name: this.lead.name, phone: this.lead.phone, s1: this.dateData })
                    this.$gtm.push({ event: 'signUpCompleted' })
                    const data = {
                        ...this.lead,
                        ...this.getToken(),
                        fullUrl: window.location.href.split('?')[0],
                    }

                    if (response) {
                        this.setStep(step)
                    }
                    this.loading = false
                    
                    try {
                        await this.$axios({
                            method: 'post',
                            url: 'postback',
                            baseURL: '/',
                            data,
                        })
                    } catch (err) {}
                }
            }
        },
        setStep (step) {
            const lead = this.$v.lead
            this.$v.$touch()
            if (this.step > step ||
               (!lead.name.$error && !lead.email.$error && this.step === 1) ||
               (!lead.phone.$error && this.step === 2) || this.step >= 3) {
                this.step = step
                this.$nextTick(() => { this.$v.$reset() })
            }
        },
        setLeadName (leadName) {
            this.lead.name = leadName
        },
        setLeadEmail (leadEmail) {
            this.lead.email = leadEmail
        },
        setLeadPhone (leadPhone) {
            this.lead.phone = leadPhone
        },
        setDateData (newDate) {
            this.dateData.date = newDate
        },
        setTimeValue (newTime) {
            this.dateData.time = newTime
        },
    },
}
