
import toggleModalWindow from '@/mixins/toggleModalWindow'
import sendWhatsAppMessage from '@/mixins/sendWhatAppMessage'
import handleToken from '@/mixins/handleToken'

export default {
    filters: {
        сomma (value, index, length) {
            if (index + 1 < length) { return `${value},` }
            return value
        },
    },
    mixins: [toggleModalWindow, handleToken, sendWhatsAppMessage],
    props: {
        expert: {
            type: Object,
            required: true,
            default: Object,
        },
        isSlider: {
            type: Boolean,
            default: true,
        },
        isAbsoluteImage: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        setAgent () {
            if (this.isSlider) {
                this.$store.commit('setCurrentAgent', this.expert)
                this.openModal('request-call-modal')
            }
        },
    },
}
