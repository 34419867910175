
import toggleModalWindow from '@/mixins/toggleModalWindow'

export default {
    mixins: [toggleModalWindow],
    props: {
        name: {
            type: String,
            require: true,
            default: String,
        },
        iconCloseBlack: {
            type: Boolean,
            require: true,
            default: Boolean,
        },
    },
    computed: {
        loading () {
            return this.$store.state.loading
        },
        currentModal () {
            return this.$store.state.currentModal
        },
    },
    watch: {
        loading () {
            if (this.currentModal) {
                this.openModal(this.name)
            }
        },
    },
    methods: {
        clearCurrentModal () {
            this.$store.commit('clearCurrentModal')
        },
        requireSrc (img) {
            return require(`~/assets/icons/controlTools/${img}.svg`)
        },
    },
}
