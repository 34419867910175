import Vue from 'vue'
import { getAgent } from 'thrivestate-lead-frontend-library'
import api from './api/api'

export async function apiGetAgents () {
    try {
        const response = await api.get('/users/agents')
        return response.data.data
    } catch (error) {
        Vue.notify({
            title: 'Error',
            text: error.response.data.message,
            type: 'error',
            duration: 3000,
        })
        return null
    }
}
export async function apiGetAgent (baseUrl = process.env.API_URL) {
    try {
        return (await getAgent(baseUrl)).data
    } catch (error) {
        Vue.notify({
            title: 'Error',
            text: error,
            type: 'error',
            duration: 3000,
        })
        return null
    }
}
