export default {
    methods: {
        async openModal (nameModal) {
            await this.$store.commit('setCurrentModal', nameModal)
            this.$modal.show(nameModal)
        },
        closeModal (nameModal) {
            this.$modal.hide(nameModal)
            this.$store.commit('clearCurrentModal')
        },
    },
}
