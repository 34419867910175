
import ClickOutside from 'vue-click-outside'

export default {
    directives: {
        ClickOutside,
    },
    props: {
        isGradient: {
            type: Boolean,
            default: false,
        },
        hasFlags: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            showDropdown: false,
        }
    },
    methods: {
        hide () {
            this.$nextTick(() => {
                this.showDropdown = false
            })
        },
        setLocale (lang) {
            this.$i18n.setLocale(lang.code)
            this.showDropdown = false
        },
        getLanguageFlagCode (lang) {
            if (lang === 'en') {
                return 'us'
            } else if (lang === 'he') {
                return 'il'
            } else if (lang === 'ko') {
                return 'kr'
            }
            return lang
        },
    },
}
