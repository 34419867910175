
export default {
    data() {
        return {
            isActiveLinks: true
        }
    },
    methods: {
        activeLinksDetector() {
            const isActive = this.$route.query.exit === '1' ? false : true
            this.isActiveLinks = isActive
        }
    },
    mounted () {
        this.activeLinksDetector();
        const WOW = require('wow.js')
        new WOW().init()
    },
}
