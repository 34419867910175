
export default {
    props: {
        value: {
            type: Object || String,
            required: true,
            default: '',
        },
        title: {
            type: String,
            default: String,
        },
        placeholder: {
            type: String,
            default: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        patternInput: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        accept: {
            type: String,
            default: null,
        },
        hasBorder: {
            type: Boolean,
            default: false,
        },
        isDisable: {
            type: Boolean,
            default: false,
        },
        helperText: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        // Radio button or checkbox value
        internalValue: {
            type: String || Number || Boolean,
            default: '',
        },
        isDarkPlaceholder: {
            type: Boolean,
            default: false,
        },
        isValueChecked: {
            type: Boolean,
            default: false,
        },
        titleColor: {
            type: String,
            default: '',
        },
        titleSpace: {
            type: String,
            default: '',
        },
        inputColor: {
            type: String,
            default: '',
        },
        placeholderColor: {
            type: String,
            default: '',
        },
        borderColor: {
            type: String,
            default: '#C39C67',
        },
    },
    data () {
        return {
            isFieldFocus: false,
        }
    },
    computed: {
        inputValue: {
            get () {
                return this.value
            },
            set (value) {
                if (this.inputValue !== value) {
                    this.inputValue = value
                }
            },
        },
        labelClasses () {
            return {
                'text-gray-8': this.isFieldFocus,
                'font-[CormorantGaramond] text-[18px] tablet:text-[24px]': this.hasBorder,
                'text-error': this.value.$error,
                [`text-[${this.titleColor}]`]: this.titleColor,
                [`mb-[${this.titleSpace}]`]: this.titleSpace,
            }
        },
        inputClasses () {
            return {
                '!border-error': this.value.$error,
                '!border-disable !text-disable placeholder:!text-disable': this.isDisable,
                'placeholder:!text-disable': this.isDarkPlaceholder,
                // eslint-disable-next-line max-len
                [`border border-gray-2 text-gray-2 rounded-[15px] tablet:rounded-[100px] px-[24px] py-[16px] text-[14px] tablet:text-[16px] focus:!border-[${this.borderColor}]`]: this.hasBorder,
                [`text-[${this.inputColor}]`]: this.inputColor,
                [`placeholder:!text-[${this.placeholderColor}]`]: this.placeholderColor,
                '!placeholder-blue-500': this.placeholderColor,
            }
        },
    },
    methods: {
        setErrorMessage (model) {
            if (model.$error) {
                switch (true) {
                case model?.required === false:
                    return 'This field is required'
                case model?.maxLength === false || model?.minLength === false:
                    return 'Phone length should be 6'
                case model?.email === false:
                    return 'Email format is wrong'
                }
            }
        },
        changeValue (value) {
            if (typeof this.inputValue === 'object') { this.inputValue.$model = value } else {
                this.inputValue = value
            }
            if (this.type === 'file') {
                this.$emit('uploadFile')
            }
        },
    },
}
