
export default {
    props: {
        leadPhone: {
            type: Object,
            require: true,
            default: Object,
        },
        leadError: {
            type: Boolean,
            require: true,
            default: false,
        },
    },
    methods: {
        setStep (step) {
            this.$emit('setStep', step)
        },
        setLeadPhone (newPhone) {
            this.$emit('setLeadEmail', newPhone)
        },
    },
}
