import Vue from 'vue'
import axios from 'axios'

export async function apiPostQuestionData (questionData) {
    try {
        const message = await axios.post('https://hooks.zapier.com/hooks/catch/13062569/3mudxv7/', questionData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        Vue.notify({
            title: 'Success',
            text: 'Information was sent successfully!' || message.status,
            type: 'success',
            duration: 3000,
        })
    } catch (error) {
        Vue.notify({
            title: 'Error',
            text: error?.response?.data?.message || error,
            type: 'error',
            duration: 3000,
        })
        console.error(error?.data?.message || error)
        return null
    }
}
