export default {
    mounted () {
        const utmTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'clickid', 'bclickid']
        if (utmTags.some(el => this.$route.query[el])) {
            const stringifiedData = JSON.stringify(this.$route.query)
            localStorage.setItem('token', stringifiedData)
            this.$cookies.set('token', stringifiedData)
            this.$store.commit('setCurrentToken', stringifiedData)
        }
    },
    methods: {
        getToken () {
            const token = localStorage.getItem('token')

            if (token) {
                return JSON.parse(token)
            }

            const tokenFromCookies = this.$cookies.get('token')

            if (tokenFromCookies) {
                return JSON.parse(token)
            }

            return this.$store.state.currentToken
        },
    },
}
