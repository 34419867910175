
import handleToken from '@/mixins/handleToken'
import toggleModalWindow from '@/mixins/toggleModalWindow'

export default {
    mixins: [handleToken, toggleModalWindow],
    props: {
        transparent: {
            type: Boolean,
            default: true,
        },
        activeLink:{
            type: Boolean,
            default: true,
        }
    },
    data () {
        return {
            isOpen: false,
            items: [
                {
                    text: this.$t('HEADER.ABOUT'),
                    to: '/#about',
                },
                {
                    text: this.$t('HEADER.SERVICES'),
                    to: '/#services',
                },
                {
                    text: this.$t('HEADER.PROPERTIES'),
                    to: '/properties',
                },
                {
                    text: this.$t('HEADER.CAREERS'),
                    to: '/careers',
                },
            ],
        }
    },
    methods: {
        drawer () {
            this.isOpen = !this.isOpen
        },
    },
}
