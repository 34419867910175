
import sanitizeSvgHtml from '@/mixins/sanitizeHtml.js'
import arrowCalendarLeft from '@/assets/icons/arrows/arrowCalendarLeft.svg?raw'
import arrowCalendarRight from '@/assets/icons/arrows/arrowCalendarRight.svg?raw'

export default {
    mixins: [sanitizeSvgHtml],
    props: {
        dateData: {
            type: Date,
            require: true,
            default: new Date(),
        },
        timeValue: {
            type: String,
            default: '',
        },
        showTimeData: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            arrowCalendarLeft,
            arrowCalendarRight,
            dateNow: new Date(),
            timeData: null,
            currentDay: new Date().getDate(),
        }
    },
    computed: {
        getNearestSesion () {
            return `${this.getMinutesNow() >= 30
                ? this.getHoursNow() + 1
                : this.getHoursNow()}:${this.getMinutesNow() <= 30 ? '30' : '00'}`
        },
        getToday () {
            const timeDate = this.getNearestSesion.split(':')
            return {
                todayWithTime: new Date(
                    this.dateNow.getFullYear(),
                    this.dateNow.getMonth(),
                    this.dateNow.getDate(),
                    timeDate[0],
                    timeDate[1],
                ),
                today: new Date(
                    this.dateNow.getFullYear(),
                    this.dateNow.getMonth(),
                    this.dateNow.getDate(),
                ),
            }
        },
        getCurrentMonth () {
            const numberMonth = this.dateNow.getMonth()
            const nameMonth = this.dateNow.toLocaleString(this.$i18n.locale, { month: 'long' })
            const countWeak = Math.ceil(
                new Date(this.getCurrentYear, numberMonth, 0).getDate() / 7)
            return {
                numberMonth,
                nameMonth,
                countWeak,
            }
        },
        getCurrentYear () {
            return this.dateNow.getFullYear()
        },
        getDaysInMonth () {
            const countDays = new Date(this.getCurrentYear, this.getCurrentMonth.numberMonth + 1, 0).getDate()
            const baseDate = new Date(Date.UTC(2023, 6, 31))
            const namesDays = []
            for (let i = 0; i < 7; i++) {
                namesDays.push(baseDate.toLocaleDateString(this.$i18n.locale, { weekday: 'short' }).slice(0, 2))
                baseDate.setDate(baseDate.getDate() + 1)
            }
            return {
                namesDays,
                countDays,
            }
        },
        getDataTime () {
            const time = []
            let dateData = null
            if (this.getToday.today.getTime() ===
                new Date(this.getCurrentYear, this.getCurrentMonth.numberMonth, this.currentDay).getTime()) {
                const timeDate = this.getNearestSesion.split(':')
                dateData = new Date(
                    this.getCurrentYear,
                    this.getCurrentMonth.numberMonth, this.dateData?.getDate(), timeDate[0], timeDate[1])
            } else {
                dateData = new Date(
                    this.getCurrentYear,
                    this.getCurrentMonth.numberMonth, this.dateData?.getDate())
            }
            const hours = dateData?.getHours()
            const minutes = dateData?.getMinutes()
            for (let i = 0; i < 24; i++) {
                if (hours < i || (hours <= i && minutes <= 0)) {
                    time.push(`${i}:00`)
                }
                if (hours < i || (hours <= i && minutes <= 30)) {
                    time.push(`${i}:30`)
                }
            }
            return time
        },
        isButtonDisable () {
            return new Date(this.getCurrentYear, this.getCurrentMonth.numberMonth, 0).getTime() < new Date().getTime()
        },
    },
    created () {
        if (this.showTimeData) {
            this.timeData = this.getNearestSesion
            this.$emit('setDateData', this.getToday.todayWithTime)
        } else {
            this.$emit('setDateData', this.getToday.today)
        }
    },
    methods: {
        setDateTime (time) {
            this.timeData = time
            const timeDate = this.timeData.split(':')
            const dateData = new Date(
                this.getCurrentYear,
                this.getCurrentMonth.numberMonth, this.dateData.getDate(), timeDate[0], timeDate[1])
            this.$emit('setDateData', dateData)
            if (this.timeValue && this.showTimeData) { this.$emit('setTimeValue', time) }
        },
        getMinutesNow () {
            return this.dateNow.getMinutes()
        },
        getHoursNow () {
            return this.dateNow.getHours()
        },
        getDayWeak (day, weakIndex) {
            const weakDay = day + weakIndex * 7
            let dataDay = null
            const fullDate = new Date(this.getCurrentYear, this.getCurrentMonth.numberMonth, weakDay)
            const today = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())
            const dateData = this.dateData
            switch (true) {
            case today > fullDate && this.isButtonDisable:
                dataDay = {
                    numberDay: weakDay,
                    disable: true,
                }
                break
            case this.getDaysInMonth.countDays >= weakDay:
                dataDay = {
                    numberDay: weakDay,
                    disable: false,
                    isActive: new Date(
                        dateData?.getFullYear(), dateData?.getMonth(), dateData?.getDate())?.getTime() ===
                        fullDate.getTime(),
                }
                break
            default:
                dataDay = {
                    numberDay: weakDay - this.getDaysInMonth.countDays,
                    disable: true,
                }
            }
            return dataDay
        },
        decreaseMonth () {
            if (!this.isButtonDisable) {
                this.dateNow = new Date(this.dateNow.setMonth(this.getCurrentMonth.numberMonth - 1))
            }
        },
        increaseMonth () {
            this.dateNow = new Date(this.dateNow.setMonth(this.getCurrentMonth.numberMonth + 1))
        },
        setDate (dayData) {
            if (!dayData.disable) {
                this.currentDay = dayData.numberDay
                const dateData = new Date(
                    this.getCurrentYear, this.getCurrentMonth.numberMonth, dayData.numberDay)
                if (this.getToday.today.getTime() === dateData.getTime()) {
                    this.timeData = this.getNearestSesion
                    this.$emit('setDateData', this.getToday.todayWithTime)
                } else {
                    this.timeData = null
                    this.$emit('setDateData', dateData)
                }
            }
        },
    },
}
