
import ClickOutside from 'vue-click-outside'
import languages from '@/data/languages.json'

export default {
    directives: {
        ClickOutside,
    },
    props: {
        value: {
            type: Object || Array,
            default: Object,
        },
        placeholder: {
            type: String,
            default: String,
        },
        isMultiSelect: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isFieldFocus: false,
            findLanguage: '',
            isVisibleListLanguages: false,
            choosedLanguages: [],
        }
    },
    computed: {
        getLanguages () {
            const listLanguages = Object.entries(languages).map(([key, value]) =>
                ({ value: value.name, label: value.nativeName }))
            if (this.findLanguage) {
                return listLanguages.filter(language =>
                    language.label.toLowerCase().includes(this.findLanguage.toLowerCase()))
            }
            return listLanguages
        },
        inputValue: {
            get () {
                return this.value
            },
            set (value) {
                this.inputValue = value
            },
        },
    },
    methods: {
        setErrorMessage (model) {
            if (model.$error) {
                switch (true) {
                case !model?.required:
                    return 'This field is required'
                case !model?.email:
                    return 'Email format is wrong'
                }
            }
        },
        changeValue (value) {
            if (this.isMultiSelect) {
                if (this.choosedLanguages.includes(value)) {
                    this.choosedLanguages = this.choosedLanguages.filter(langue => langue !== value)
                } else { this.choosedLanguages.push(value) }
                this.inputValue.$model = this.choosedLanguages
                this.findLanguage = this.choosedLanguages.join(', ')
            } else {
                this.findLanguage = value
                this.inputValue.$model = value
            }
            this.hideListLanguages()
        },
        showListLanguages () {
            this.isFieldFocus = true
            if (this.isMultiSelect) { this.findLanguage = '' } else {
                this.choosedLanguages = this.findLanguage
                this.findLanguage = ''
            }
            this.isVisibleListLanguages = true
        },
        hideListLanguages () {
            this.isFieldFocus = false
            if (this.isMultiSelect) {
                this.findLanguage = this.choosedLanguages.join(', ')
            } else if (this.findLanguage === '') {
                this.findLanguage = this.choosedLanguages
                this.choosedLanguages = ''
            }
            this.isVisibleListLanguages = false
        },
    },
}
