
import toggleModalWindow from '@/mixins/toggleModalWindow'

export default {
    mixins: [toggleModalWindow],
    props: {
        leadName: {
            type: Object,
            require: true,
            default: Object,
        },
        leadEmail: {
            type: Object,
            require: true,
            default: Object,
        },
    },
    methods: {
        setStep (step) {
            this.$emit('setStep', step)
        },
        setLeadName (newName) {
            this.$emit('setLeadName', newName)
        },
        setLeadEmail (newEmail) {
            this.$emit('setLeadEmail', newEmail)
        },
    },
}
