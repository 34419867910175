export default {
    methods: {
        async sendWhatsAppMessage (number = this.$t('WHATS_APP.PHONE_NUMBER')) {
            this.$gtm.push({ event: 'instantMessangerClick' })
            window.open(
                `https://api.whatsapp.com/send?phone=${
                    number}`,
                '_blank',
            )
            this.$gtm.push({ event: 'contactViaWA' })
            await this.$axios({
                method: 'post',
                url: 'started_chat',
                baseURL: '/',
                data: this.getToken(),
            })
        },
    },
}
