

export default {
    props: {
        dateData: {
            type: Object,
            require: true,
            default: Object,
        },
        leadError: {
            type: Boolean,
            require: true,
            default: false,
        },
        loading: {
            type: Boolean,
            require: true,
            default: false,
        },
    },
    methods: {
        createLead (step) {
            this.$emit('createLead', step)
        },
        setDateData (newDate) {
            this.$emit('setDateData', newDate)
        },
        setTimeValue (newTime) {
            this.$emit('setTimeValue', newTime)
        },
    },
}
