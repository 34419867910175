
import handleToken from '@/mixins/handleToken'
import toggleModalWindow from '@/mixins/toggleModalWindow'
import sendWhatsAppMessage from '@/mixins/sendWhatAppMessage'
import { apiGetAgent } from '@/services/agents'

export default {
    mixins: [handleToken, toggleModalWindow, sendWhatsAppMessage],
    props: {
        isSimpleFooter: {
            type: Boolean,
            default: false,
        },
        isLargeFooter: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            agent: null,
            swiperOptions: {
                grabCursor: true,
                navigation: {
                    nextEl: '.swiper-house-button-next',
                    prevEl: '.swiper-house-button-prev',
                },
                pagination: {
                    el: '.swiper-house-pagination',
                    clickable: true,
                },
                scrollbar: {
                    el: '.swiper-house-scrollbar',
                },
                breakpoints: {
                    1440: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    580: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                },
            },
            sliderData: [
                {
                    id: 0,
                    image: 'House1.jpg',
                },
                {
                    id: 1,
                    image: 'House2.jpg',
                },
                {
                    id: 2,
                    image: 'House3.jpg',
                },
            ],
        }
    },
    computed: {
        houseSwiper () {
            return this.$refs.houseSwiper?.$swiper
        },
        getAgentNumber () {
            return this.agent.phone_country.phone_code + this.agent.phone
        },
    },
    async mounted () {
        this.agent = await apiGetAgent()
    },
    methods: {
        requireSrc (img) {
            return require(`~/assets/images/sliders/houseSlider/${img}`)
        },
    },
}
