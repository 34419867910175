export const state = () => ({
    currentModal: null,
    loading: false,
    currentAgent: null,
    currentToken: null,
    leadId: null,
});

export const mutations = {
    setCurrentModal(state, nameModal) {
        state.currentModal = nameModal;
    },
    clearCurrentModal(state) {
        state.currentModal = null;
    },
    startLoading(state) {
        state.loading = true;
    },
    endLoading(state) {
        state.loading = false;
    },
    setCurrentAgent(state, agent) {
        state.currentAgent = agent;
    },
    setCurrentToken(state, token) {
        state.currentToken = token;
    },
    setLeadId(state, leadId) {
        state.leadId = leadId;
    },
};

export const actions = {};

export const getters = {};
